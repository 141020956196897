import './App.scss';
import logo from "./components/assets/AMTLogo.png";
import BloodForm from './components/forms/BloodForm';
import { Routes, Route } from "react-router-dom";
import Page404 from './components/pages/404Page';
import HomePage from './components/pages/HomePage';
import AmazonHomePage from './components/pages/AmazonHomePage';
import HairForm from './components/forms/HairForm';
import AmazonBloodForm from './components/forms/AmazonBloodForm';
import AmazonHairForm from './components/forms/AmazonHairForm';
import HairRetestForm from './components/forms/HairRetestForm';
import HairInstructionsPage from './components/pages/HairInstructionsPage';
import BloodInstructionsPage from './components/pages/BloodInstructionsPage';
import BloodThankYouPage from './components/pages/BloodThankYouPage';
import HairThankYouPage from './components/pages/HairThankYouPage';
import PrivacyPolicyPage from './components/pages/PrivacyPolicyPage';
 import { NavLink } from "react-router-dom";

function App() {
  return (
    <div className="app">

      <img
        src={logo}
        alt="Activate My Test Logo"
        className="app__logo"
      />

      <Routes>       
        <Route path="/" element={ <HomePage/> }/>
        <Route path="/amazon" element={ <AmazonHomePage/> }/>
        <Route path="/hair-submissions" element={ <HairForm/> } />
        <Route path="/hair-retest" element={ <HairRetestForm/> }></Route>
        <Route path="/blood-submissions" element={ <BloodForm/> } />
        <Route path="/amazon/hair-submissions" element={ <AmazonHairForm/> } />
        <Route path="/amazon/blood-submissions" element={ <AmazonBloodForm/> } />
        <Route path="/hair" element={ <HairInstructionsPage/> } />
        <Route path="/blood" element={ <BloodInstructionsPage/> } />
        <Route path="/thank-you-hair/:code/:name" element={ <HairThankYouPage/> }/>
        <Route path="/thank-you-blood" element={ <BloodThankYouPage/> } />
        <Route path="/privacy-policy" element={ <PrivacyPolicyPage/> } />
        <Route path="*" element={ <Page404/> }/> 
      </Routes>

    <footer className="footer">
      <p>&copy; 2024 Healthy Stuff Group Limited. All rights reserved. | <NavLink to="/privacy-policy" style={{ color: "#FFAE42" }}>Privacy Policy</NavLink></p>
    </footer>
      
    </div>
  );
}

export default App;
